import React, { useState, useEffect } from 'react';
import EntryForm from './EntryForm';
import api from '../context/api';
import { useNavigate, useParams } from 'react-router-dom';

const EntryDetails = () => {
  // Initialize form state with empty values or default values
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token'); 
  let { id } = useParams();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [updatesData, setUpdatesData] = useState([]);
  const [updates, setUpdates] = useState({
        date: '',
        update: ''
    });
  const [formData, setFormData] = useState({
    id: '',
    location: '',
    source: '',
    case: '',
    date: '',
    cause: '',
    legal: '',
    reference: '',
    injured: '',
    incurred: '',
    loss: '',
    restric: '',
    outpayment: '',
    deductible: '',
    shortDescription: '',
    video: false,
    medical: false
  });

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Notice the `await` keyword here
          const response = await api.get(`/${user.id}/entry/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });

          // The status code for a successful GET request is usually 200
          if (response.status === 202) {
              setFormData({
                id: response.data.id || 0,
                location: response.data.location || '',
                source: response.data.source || '',
                case: response.data.case || '',
                cause: response.data.cause || '',
                date: response.data.date ? response.data.date.split('T')[0] : '', 
                injured: response.data.injured || '',
                reference: response.data.reference || '',
                legal: response.data.legal || '',
                outpayment: response.data.outpayment || 0,
                deductible: response.data.deductible || 0,
                loss: response.data.loss || '',
                restrict: response.data.restrict || '',
                incurred: response.data.incurred || '',
                shortdesc: response.data.shortdesc || '',
                video: response.data.video || false,
                medical: response.data.medical || false,
              });
          }

          fetchUpdates();

        } catch (error) {
          console.error("An error occurred:", error.response || error);
        }
      };

      if (user && token && id) {
        fetchData();
      }
    }, []);

  const handleInputChange = (field, value) => {
    // Update the form data state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token'); 
    try {
      const response = await api.patch(`/${user.id}/entry/${id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 202 || response.status === 205) {
        navigate('/entries');
      }

    } catch (error) {
      // Handle error here, such as showing an error message to the user
      console.error("An error occurred:", error.response || error);
    }
    // Perform the submit action, such as sending data to an API or server
  };

  const fetchUpdates = async () => {
    const token = localStorage.getItem('token'); 
    try {
      const response = await api.get(`/entry/${id}/update`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 202 || response.status === 201) {
        setUpdatesData(response.data);
      }

    } catch (error) {
      // Handle error here, such as showing an error message to the user
      console.error("An error occurred:", error.response || error);
    }
  };

  const addUpdates = async () => {
    const token = localStorage.getItem('token'); 
    try {
      const response = await api.post(`/entry/${id}/update`, updates, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status === 202 || response.status === 201) {
        setUpdates({date: '', update: ''});
        fetchUpdates();
      }

    } catch (error) {
      console.error("An error occurred:", error.response || error);
    }
  };

  const handleUpdatesChange = (field, value) => {
    setUpdates((prevFormData) => ({
      ...prevFormData,
      [field]: value
    }));
  };


  const handleCancel = () => {
    navigate('/entries');
  };

  const [mode, setMode] = useState('view');

  return (
    <EntryForm
      mode={mode}
      setMode={setMode}
      data={formData}
      onDataChange={handleInputChange}
      onSubmit={handleSubmit}

      updatesData={updatesData}
      updates={updates}
      onDataUpdatesChange={handleUpdatesChange}
      onSubmitUpdates={addUpdates}

      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}

      onCancel={handleCancel}
    />
  );
};

export default EntryDetails;
